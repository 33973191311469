.page-container {
  /* background-color: red; */

  display: flex;
  flex-direction: column;
  /* text-align: center; */
  justify-content: center;
  align-items: center; 
  padding: 100px;
}

.content-list {
  /* background-color: yellow; */
  width: 500px;
  margin-bottom: 20px; /* Espaçamento entre itens da lista */
}

.term-title {
  font-weight: bold;
  font-size: 24px;
}

.term-subtitle {
  font-size: 18px;
  color: #777;
}

.term-content {
  font-size: 16px;
}
