

.navbar {
    overflow: hidden;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 20px;
    background-color: teal;
    color: aliceblue;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    position: fixed;
  top: 0;
  width: 100%;
}

.logo {
    font-size: 32px;
  }