.header {
  background-color: rgb(23, 158, 124);
  padding: 10px;
  text-align: center;
  top: 0;
  z-index: 1;
}
.header h1 {
  margin: 0;
  font-size: 24px;
}
